@import url('https://fonts.googleapis.com/css2?family=Lato&family=Poppins&family=Raleway&family=Ubuntu&family=Roboto&display=swap');

* {
    margin: 0;
    padding: 0;
    font-family: "Roboto", "Arial", sans-serif;
}

.navbar {
    padding: 0.75rem 1rem!important;
}

.navbar-brand {
    font-family: "Poppins", "Arial", sans-serif;
    font-size: 1.5rem;
    font-weight: 500;
}

a:hover {
    color: #4141c7 !important;
}

.bg-custom-light {
    background-color: rgb(236, 236, 236)!important;
}

.nav-link {
    margin: 0 1rem;
    font-size: 1.1rem!important;
}

.icon {
    position: relative;
    bottom: 2px;
    height: 1.5rem;
}

.mainContainer {
    padding: 1rem 1.5rem;
}

.title {
    font-family: 'Ubuntu';
    font-size: 1.5rem;
    font-weight: 600;
}

.subText {
    font-family: 'Lato';
    font-size: 1.25rem;
    font-weight: 400;
}

.amenity {
    margin-top: 0.5rem;
    font-size: 1.25rem;
    font-weight: 400;
    list-style: none;
}

.amenity::before {
    content: "\21A3";
    font-weight: 600;
    font-size: 1.4rem;
    padding-right: 1rem;
}

.featuresContainer {
    margin-top: 1.5rem;
}

.featureBox {
    margin: 1rem;
    text-align: center;
}

.card .card-header {
    padding: 1rem;
    font-size: 1.5rem;
    text-align: center;
}

.card .card-body .card-title {
    font-size: 1.35rem;
    text-align: center;
}

.card .card-body .card-text {
    padding: 1rem;
    font-size: 1.25rem;
    text-align: center;
}

.card button {
    text-align: center;
    font-size: 1.25rem;
}

footer {
    padding: 1rem;
    text-align: center;
    font-size: 1.25rem;
}

.socialLinks a {
    font-size: 1.8rem!important;
    display: inline-flex;
    background-color: #fff;
    width: 3rem;
    height: 3rem;
    align-items: center;
    justify-content: center;
    padding: 1.3rem;
    border-radius: 50%;
    color: rgb(101, 101, 101)!important;
    margin: 0 1rem!important;
    transition: 0.5s!important;
}

.socialLinks a:hover {
    color: #000!important;
}

.screenshot {
    height: 25vw;
    border: 5px solid #5b5b5b;
    border-radius: 2vw;
    margin: 2rem;
}

.includes {
    font-size: 1.25rem;
    font-weight: 400;
    list-style: none;
}

.includes::before {
    font-weight: 600;
    font-size: 1.4rem;
    padding-right: 1rem;
}

.notIncludes {
    font-size: 1.25rem;
    font-weight: 400;
    list-style: none;
}

.notIncludes::before {
    font-weight: 600;
    font-size: 1.4rem;
    padding-right: 1rem;
}

a.payment:hover {
    color: #fff!important;
}
